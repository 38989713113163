<template>
  <div class="avatar" :class="{ 'avatar-hover': hover }">
    <div class="avatar-photo" :title="photoTitle ? photoTitle : title">
      <div :style="{ backgroundImage: 'url(' + (photo ? photo : require('@/assets/img/nophoto.png')) + ')' }"></div>
    </div>
    <div class="avatar-info">
      <div class="avatar-title">{{ title }}</div>
      <div class="avatar-desc" v-if="desc">{{ desc }}</div>
      <div class="avatar-desc" v-else><slot name="desc"></slot></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    photo: String,
    photoTitle: String,
    title: String,
    desc: String,
    hover: Boolean
  }
}
</script>

<style scoped>
.avatar {
  display: flex;
}
.avatar-photo div {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  margin-right: 15px;
  background-size: cover;
}
.avatar-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.avatar-title {
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: var(--title-color);
}
.avatar-hover:hover .avatar-title {
  color: var(--text-color);
}
.avatar-desc {
  font-size: 12px;
  line-height: 16px;
  color: var(--text-color);
  margin-top: 3px;
}
</style>
