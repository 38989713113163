<template>
  <div>
    <Block>
      <div class="step-header">
        <div class="step-title">{{ step.title }}</div>
        <button class="btn btn-secondary" @click="skipWizard">Пропустить настройки</button>
      </div>
      <div class="wizard-steps">
        <div class="wizard-step" v-for="(s, index) in steps" :key="index" :class="{ active: index <= current }"></div>
      </div>
      <div>
        <div class="user-plan" v-if="step.code == 'user_plan'">
          <div
            v-for="item in users"
            :key="item.id"
            class="plan-user"
          >
            <div class="plan-user-action-icon">
              <div
                v-if="isAddedToPlan(item.id)"
                @click="removeUserFromPlan(item.id)"
                role="button"
              >
                <img :src="require(`@/assets/svg/success_icon_lg.svg`)" />
              </div>
              <div
                v-else
                class="empty"
                @click="addUserToPlan(item.id)"
                role="button"
              ></div>
            </div>
            <Avatar :photo="item.avatar" :title="item.name">
              <template #desc>
                <span
                  v-if="isAddedToPlan(item.id)"
                  class="dotted-red"
                  role="button"
                  @click="removeUserFromPlan(item.id)"
                >Убрать</span>
                <span
                  v-else
                  class="dotted-blue"
                  role="button"
                  @click="addUserToPlan(item.id)"
                >Добавить</span>
              </template>
            </Avatar>
          </div>
        </div>
        <div v-else-if="step.code === 'bill_date'">
          <div class="bill-date">
            <div class="bill-date-col">
              <div class="bill-date-title">В плане продаж</div>
              <div class="bill-date-input"><b-form-input value="Сделка оплачена" disabled /></div>
              <div class="bill-date-input"><b-form-input value="Выставлен счет" disabled /></div>
            </div>
            <div class="bill-date-col">
              <div class="bill-date-title">В вашем приложении Битрикс24</div>
              <div class="bill-date-input"><b-form-input v-model="step.paid_deal_stage_name" placeholder="Введите название стадии" /></div>
              <div class="bill-date-input"><b-form-input v-model="step.predict_deal_stage_name" placeholder="Введите название стадии" /></div>
            </div>
          </div>
        </div>
        <div v-else>
          <b-form-radio-group
            v-if="step.options"
            v-model="step.value"
            :options="step.options"
          />
          <b-input
            v-else
            v-model="step.value"
            style="max-width: 200px"
          />
        </div>
      </div>
      <div class="step-buttons">
        <button v-if="current !== 0" class="btn btn-secondary" @click="prev">Назад</button>
        <button v-if="current < steps.length - 1" class="btn btn-primary" @click="next">Далее</button>
        <button v-if="current === steps.length - 1" class="btn btn-primary" @click="done">Создать план</button>
      </div>
    </Block>

    <div v-for="(desc, index) in step.desc" :key="index" class="desc">
      <div v-if="desc.title" class="desc-title">{{ desc.title }}</div>
      <div v-if="step.code === 'min_sum_mode' && index === 1" class="desc-text desc-text-minsummode">
        <p>
          <SpriteIcon icon="question" class="mr-2 sm" tooltipText="Выполнение менеджерами указанного показателя позволит компании покрыть все расходы и не попасть в кассовый разрыв" />
          <strong>Нижний порог плана</strong> - выполнение менеджерами указанного показателя позволит компании покрыть все расходы и не попасть в кассовый разрыв.
        </p>
        <p>
          <SpriteIcon icon="question" class="mr-2 sm" tooltipText="Выполнение менеджерами данного показателя позволит компании активно расти и развиваться" />
          <strong>План</strong> - выполнение менеджерами данного показателя позволит компании активно расти и развиваться.
        </p>
      </div>
      <div v-else-if="desc.text && Array.isArray(desc.text)" class="desc-text"><p v-for="(p, i) in desc.text" :key="i">{{ p }}</p></div>
      <div v-else-if="desc.text" class="desc-text">{{ desc.text }}</div>
      <div v-if="desc.img" class="desc-img"><img :src="require('@/assets/img/sale/wizard/' + desc.img)" alt=""></div>
    </div>
  </div>
</template>

<script>
import Api from '@/common/api.service'
import Avatar from '@/components/ui/Avatar'

export default {
  name: 'Wizard',
  components: {
    Avatar
  },
  data () {
    return {
      current: 0,
      users: [],
      steps: [
        /* {
          title: 'Какой режим плана продаж вы хотите?',
          code: 'planning_mode',
          value: 'N',
          options: [
            { value: 'Y', text: 'С предварительным планированием' },
            { value: 'N', text: 'Без предварительного планирования' }
          ],
          desc: [
            {
              title: 'Что такое планрование?',
              text: 'Планирование позволяет отслеживать в реальном времени процент вероятности выполнения плана',
              img: 'desc1_1.png'
            },
            {
              title: 'Работа с моими клиентами',
              text: 'Мы понимаем работа с постоянной базой клиентов должна быть удобной и наглядной. и еще какое то описание',
              img: 'desc1_2.png'
            }
          ]
        }, */
        {
          title: 'В чем отображать планы менеджеров в рейтинге?',
          code: 'measure',
          value: 'R',
          options: [
            { value: 'R', text: 'Рубли' },
            { value: 'P', text: 'Проценты' }
          ],
          desc: [
            {
              title: 'Что выбрать?',
              text: 'Некоторые компании не хотят показывать сотрудникам планы продаж коллег в валюте. Причины для этого могут быть самыми разными. Чтобы выйти из положения и сохранить соревновательный дух в коллективе, руководители прибегают к отображению рейтинга в процентах. Как настроить в вашей компании - решать вам. Выбирайте приемлемый вариант, а система поможет поддержать в команде дух соперничества.',
              img: 'desc2_1.png'
            }
          ]
        },
        /* {
          title: 'Кому вы будете продавать?',
          code: 'segment',
          value: 'B2B',
          options: [
            { value: 'B2B', text: 'B2B' },
            { value: 'B2C', text: 'B2C' }
          ],
          desc: [
            {
              title: 'В чем разница?',
              text: 'Главное отличие это как работают компании, через контакт или сделки',
              img: 'desc3_1.png'
            }
          ]
        }, */
        {
          title: 'Хотите установить точку безубыточности - нижний порог выполнения плана?',
          code: 'min_sum_mode',
          value: true,
          options: [
            { value: true, text: 'Да' },
            { value: false, text: 'Нет' }
          ],
          desc: [
            {
              title: 'Точка безубыточности (ТБУ)',
              text: 'Показатель, означающий, что у компании достаточно денег, чтобы заплатить зарплату сотрудникам и оплатить остальные расходы в отчетном периоде.',
              img: 'desc4_1.png'
            },
            {
              title: 'Обозначьте менеджерам нижний порог плана, чтобы видеть ТБУ на шкале прогресса и оперативно реагировать в случае, если что-то идет не так.',
              text: [
                'Нижний порог плана - выполнение менеджерами указанного показателя позволит компании покрыть все расходы и не попасть в кассовый разрыв.',
                'План - выполнение менеджерами данного показателя позволит компании активно расти и развиваться.'
              ],
              img: 'desc4_2.png'
            }
          ]
        },
        /* {
          title: 'Какой режим работы со сделками?',
          code: 'deal_mode',
          value: 'closed',
          options: [
            { value: 'closed', text: 'Успешные закрытые сделки' },
            { value: 'status', text: 'С определенной стадии сделок' }
          ],
          desc: [
            {
              title: 'В чем разница?',
              text: 'Текст отличия работы по закрытым сделкам и по сделкам со стадиями'
            }
          ]
        }, */
        {
          title: 'Название стадии сделок, когда счет оплачен',
          code: 'bill_date',
          paid_deal_stage_name: null,
          predict_deal_stage_name: null,
          desc: [
            {
              title: 'Для чего это нужно?',
              text: [
                'В Битрикс24 каждая сделка в процессе работы проходит определенные стадии согласно её воронке.',
                'В плане продаж сделки сортируются по критериям: “сделка оплачена”, “выставлен счет” и “в работе”.',
                'Чтобы план продаж отображал сделки, отвечающие данным критериям в нужных вкладках, необходимо сопоставить названия стадий сделок в вашем Битрикс24 с критериями сделок в плане продаж.'
              ],
              img: 'desc5_1.png'
            }
          ]
        },
        {
          title: 'Как рассчитывать сумму сделок',
          code: 'deal_sum',
          value: 'mixed',
          options: [
            { value: 'mixed', text: 'По товарам, если они есть в сделке. Если нет, то по полю "сумма сделки"' },
            { value: 'product', text: 'По товарам' },
            { value: 'field', text: 'По полю "сумма сделки"' }
          ],
          desc: [
          ]
        },
        {
          title: 'Добавьте сотрудников в план',
          code: 'user_plan',
          value: []
        }
      ]
    }
  },
  async beforeRouteEnter (to, from, next) {
    const isSynced = await Api.query('sale/wizard/check-sync')
    if (!isSynced) {
      next({ name: 'sale/sync' })
      return
    }
    next()
  },
  computed: {
    step () {
      return this.steps[this.current]
    }
  },
  async created () {
    await this.fetchUsers()
  },
  methods: {
    next () {
      this.current++

      if (this.skipStep()) {
        this.next()
      }
    },
    prev () {
      this.current--

      if (this.skipStep()) {
        this.prev()
      }
    },
    skipStep () {
      if (!this.step.required) {
        return false
      }

      const requiredEl = this.steps.find(el => el.code === this.step.required.code)

      return requiredEl.value !== this.step.required.value
    },
    async done () {
      const options = {}
      this.steps.forEach(el => {
        options[el.code] = el.value
      })

      options.planning_mode = false

      delete options.bill_date
      options.paid_deal_stage_name = this.steps.find(el => el.code === 'bill_date').paid_deal_stage_name
      options.predict_deal_stage_name = this.steps.find(el => el.code === 'bill_date').predict_deal_stage_name

      await this.$api.post('sale/wizard/done', options)
      this.$router.push({ name: 'sale/plan' })
    },
    async skipWizard () {
      await this.$api.post('sale/wizard/done')
      this.$router.push({ name: 'sale/plan' })
    },
    async fetchUsers () {
      await this.$api.query('user/active').then(response => {
        this.users = response
      })
    },
    addUserToPlan (userId) {
      const planIndex = this.step.value.findIndex(el => el.user_id === userId)

      if (planIndex === -1) {
        this.step.value.push({
          user_id: userId,
          sum: 0,
          wish_sum: 0,
          min_sum: 0,
          status: 'empty'
        })
      }
    },
    removeUserFromPlan (userId) {
      const planIndex = this.step.value.findIndex(el => el.user_id === userId)

      if (planIndex !== -1) {
        this.step.value.splice(planIndex, 1)
      }
    },
    isAddedToPlan (userId) {
      return this.step.value.findIndex(el => el.user_id === userId) !== -1
    }
  }
}
</script>

<style scoped>
.step-header {
  display: flex;
  justify-content: space-between;
}
.step-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: var(--title-color);
}
.wizard-steps {
  display: flex;
  margin: 20px 0 30px;
}
.wizard-step {
  border-radius: 4px;
  background-color: var(--border-color);
  width: 65px;
  height: 1px;
  margin-right: 6px;
}
.wizard-step.active {
  background-color: var(--title-color);
}
.step-buttons {
  margin-top: 30px;
}
.step-buttons button {
  margin-right: 10px;
}
.desc {
  margin-bottom: 50px;
}
.desc-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: var(--title-color);
}
.desc-text,
.desc-text p {
  font-size: 13px;
  line-height: 15px;
  color: var(--title-color);
}
.desc-text-minsummode p {
  line-height: 20px;
}
.desc-img {
  border: 2px solid #FFFFFF;
  padding-top: 60px;
  padding-bottom: 65px;
  display: flex;
  justify-content: center;
}
.desc-title + .desc-text {
  margin-top: 10px;
}
.desc-title + .desc-img,
.desc-text + .desc-img {
  margin-top: 30px;
}

/* bill_date step */
.bill-date {
  display: flex;
}
.bill-date-col {
  width: 330px;
}
.bill-date-title {
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: var(--tilte-color);
  margin-bottom: 15px;
}
.bill-date-input {
  margin-bottom: 30px;
  position: relative;
}
.bill-date-col .bill-date-input:last-child {
  margin-bottom: 10px;
}
.bill-date .bill-date-col:first-child {
  margin-right: 50px;
}
.bill-date .bill-date-col:first-child .bill-date-input::after {
  content: '';
  position: absolute;
  right: -30px;
  top: 19px;
  width: 10px;
  height: 2px;
  background-color: var(--text-color);
}

/* user plan */
.user-plan {
  max-height: 570px;
  overflow-y: auto;
}
.plan-user {
  display: flex;
  padding: 15px 0;
  border-top: 1px solid var(--border-color);
}
.plan-user-divider-top {
  border-top: 1px solid var(--border-color);
  margin-top: 25px;
  padding-top: 25px;
}
.plan-user-action-icon > div {
  width: 36px;
  height: 36px;
  margin-right: 15px;
}
.plan-user-action-icon > div.empty {
  border: 1px solid var(--border-color);
  border-radius: 50%;
}
</style>
